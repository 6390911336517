
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import * as WorkshopFormsService from "@/services/WorkshopFormsService";
import { format } from "date-fns";

@Component
export default class WorkshopFormsList extends Vue {

    workshops = [] as any[]

    dialogs = {
        addForm: false
    }

    loadingWorkshops = false;

    loadingNewForm = false;

    newFormId = '';

    async mounted() {
        this.loadingWorkshops = true;
        this.workshops = await WorkshopFormsService.ListForms();
        this.loadingWorkshops = false;
    }

    get workshopList() {
        const resultArray = [] as any[];
        this.workshops.forEach(item => {
            const name = item.name.split(" (")[0];
            const type = item.name.includes("(ante)") ? "ante" : "post";
            const index = resultArray.findIndex(obj => obj.name === name);

            if (index === -1) {
                let formItem = {
                    name: name,
                    [type]: item.totalResponses,
                    [`${type}Id`]: item.id
                }

                if (item.date) {
                    formItem.date = format(new Date(item.date), 'dd/MM/yyyy')
                }

                resultArray.push(formItem);
            } else {
                resultArray[index][type] = item.totalResponses;
                resultArray[index][`${type}Id`] = item.id;
            }

        });
        return resultArray;
    }

    get totalAnteResponses() {
        return this.workshopList.reduce((acc, cur) => acc + cur.ante, 0);
    }

    get totalPostResponses() {
        return this.workshopList.reduce((acc, cur) => acc + cur.post, 0);
    }

    async submit() {
        try {
            this.loadingNewForm = true;
            await WorkshopFormsService.AddForm(this.newFormId);

            this.dialogs.addForm = false;
            this.workshops = await WorkshopFormsService.ListForms();
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: error
            });
        } finally {
            this.loadingNewForm = false;
        }
    }

    open(id: string) {
        window.open(`https://docs.google.com/forms/d/${id}/edit`)

    }
}

